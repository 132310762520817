<template>
  <b-tabs>
    <b-tab
      active
      no-body
      title="Table"
    >
      <programs-list-filters
        :category-filter.sync="categoryFilter"
        :category-options="categoryOptions"
        :is-active-filter.sync="isActiveFilter"
        :is-active-options="statusOptions"
        :is-special-filter.sync="isSpecialFilter"
        :is-free-filter.sync="isFreeFilter"
        :is-special-options="statusOptions"
        :date.sync="dateFilter"
      />
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  v-if="$can('program:create')"
                  :to="{ name: 'add-program' }"
                  variant="primary"
                  @click="isAddNewTagsSidebarActive = true"
                >
                  <span class="text-nowrap">Add program</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <b-overlay
          :show="showTable"
          rounded="sm"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <b-table
            ref="refTagsTable"
            class="position-relative"
            :items="fetchPrograms"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: preview -->
            <template #cell(preview)="data">
              <b-link
                :to="{ name: 'edit-program', params: { id: data.item.id } }"
              >
                <b-img
                  height="60"
                  width="100"
                  :src="VALID_IMAGE + data.item.image.key"
                />
              </b-link>

            </template>
            <!-- Column: Title -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to="{ name: 'edit-program', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
              </b-media>
            </template>
            <!-- Column: canReserved -->
            <template #cell(canReserved)="data">
              <span class="text-capitalize">
                <b-form-checkbox
                  :disabled="!$can('program:update')"
                  :checked="data.item.canReserved"
                  switch
                  @change="switchToggle(data.item, 3)"
                />
              </span>
            </template>
            <!-- Column: isSpecial -->
            <template #cell(isSpecial)="data">
              <span class="text-capitalize">
                <b-form-checkbox
                  :disabled="!$can('program:update')"
                  :checked="data.item.isSpecial"
                  switch
                  @change="switchToggle(data.item, 2)"
                />
              </span>
            </template>
            <!-- Column: isSpecial -->
            <template #cell(isFree)="data">
              <span class="text-capitalize">
                <b-form-checkbox
                  :disabled="!$can('program:update')"
                  :checked="data.item.isFree"
                  switch
                  @change="switchToggle(data.item, 5)"
                />
              </span>
            </template>
            <!-- Column: isBanner -->
            <template #cell(isBanner)="data">
              <span class="text-capitalize">
                <b-form-checkbox
                  :disabled="!$can('program:update')"
                  :checked="data.item.isBanner"
                  switch
                  @change="switchToggle(data.item, 4)"
                />
              </span>
            </template>
            <!-- Column: isActive -->
            <template #cell(isActive)="data">
              <span class="text-capitalize">
                <b-form-checkbox
                  :disabled="!$can('program:update')"
                  :checked="data.item.isActive"
                  switch
                  @change="switchToggle(data.item, 1)"
                />
              </span>
            </template>
            <!-- Column: CreatedAt -->
            <template #cell(created_at)="data">
              <span class="text-capitalize">
                {{ formatDate(data.item.created_at) }}
              </span>
            </template>
            <!-- Column: CreatedAt -->
            <template #cell(category)="data">
              <span
                v-if="mapCategory(data.item.categoryId) !== 'None'"
                class="text-capitalize"
              >
                {{ mapCategory(data.item.categoryId) }}
              </span>
              <b-badge
                v-else
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                None
              </b-badge>
            </template>
            <!-- Column: dateRange -->
            <template #cell(dateRange)="data">
              <span class="text-capitalize">
                {{ formatDate(data.item.startDate) +' - '+ formatDate(data.item.endDate) }}
              </span>
            </template>
            <!-- Column: timeRange -->
            <template #cell(timeRange)="data">
              <span class="text-capitalize">
                {{ formatTime(data.item.startTime) +' - '+ formatTime(data.item.endTime) }}
              </span>
            </template>
            <!-- Column: status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveStatusProgram(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <!-- Column: Actions -->
            <template #cell(action)="data">
              <b-link
                v-if="$can('program:update')"
                :to="{ name: 'edit-program', params: { id: data.item.id } }"
              >
                Edit
              </b-link>
              <b-link
                v-if="$can('program:delete')"
                @click="deleteProgram(data.item.id)"
              >
                Delete
              </b-link>
            </template>

          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalPrograms"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-tab>
    <b-tab
      lazy
      no-body
      title="Calendar"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <div class="app-calendar overflow-hidden border">
          <div class="row no-gutters">
            <!-- Sidebar -->
            <div
              class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
              :class="{'show': isCalendarOverlaySidebarActive}"
            >
              <calendar-sidebar
                :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
                :category-filter.sync="categoryFilterCalendar"
                :category-options="categoryOptions"
              />
            </div>

            <!-- Calendar -->
            <div class="col position-relative">
              <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                  <full-calendar
                    ref="refCalendar"
                    :options="calendarOptions"
                    class="full-calendar"
                  />
                </div>
              </div>
            </div>

            <!-- Sidebar Overlay -->
            <div
              class="body-content-overlay"
              :class="{'show': isCalendarOverlaySidebarActive}"
              @click="isCalendarOverlaySidebarActive = false"
            />
            <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
          </div>
        </div>
      </b-overlay>

    </b-tab>
  </b-tabs>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import {
  BTab, BTabs, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BFormCheckbox, BBadge, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import ProgramsListFilters from '@/components/programManager/ProgramsListFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { VALID_IMAGE } from '@/utils/config'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import useTable from './useTable'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BTab,
    BTabs,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    ProgramsListFilters,
    BFormCheckbox,
    BBadge,
    BImg,
  },
  setup() {
    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      loading,
      categoryFilterCalendar,
    } = useCalendar()
    const {
      timeout,
      showTable,
      fetchPrograms,
      tableColumns,
      perPage,
      currentPage,
      totalPrograms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      categoryOptions,
      dateFilter,
      categoryFilter,
      isActiveFilter,
      isSpecialFilter,
      isFreeFilter,
      statusOptions,
      resolveStatusProgram,
      typeFilter,
    } = useTable()
    fetchEvents()
    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      loading,

      // ----- UI ----- //
      isEventHandlerSidebarActive,

      // ----- Table ----- //
      timeout,
      showTable,
      fetchPrograms,
      tableColumns,
      perPage,
      currentPage,
      totalPrograms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      categoryOptions,
      dateFilter,
      categoryFilter,
      isActiveFilter,
      isSpecialFilter,
      isFreeFilter,
      statusOptions,
      resolveStatusProgram,
      ref,
      categoryFilterCalendar,
      typeFilter,
      VALID_IMAGE,
    }
  },
  methods: {
    formatTime(time) {
      return moment(time, 'HH:mm').format('HH:mm')
    },
    mapCategory(data) {
      if (this.categoryOptions.find(x => x.value === data)) {
        return this.categoryOptions.find(x => x.value === data).label
      }
      return 'None'
    },
    showToast(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Update ${data} success`,
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    switchToggle(program, type) {
      const payload = {}
      switch (type) {
        case 1:
          payload.isActive = !program.isActive
          break
        case 2:
          payload.isSpecial = !program.isSpecial
          if (payload.isSpecial && program.isFree) {
            payload.isFree = false
          }
          break
        case 3:
          payload.canReserved = !program.canReserved
          break
        case 4:
          payload.isBanner = !program.isBanner
          break
        case 5:
          payload.isFree = !program.isFree
          if (payload.isFree && program.isSpecial) {
            payload.isSpecial = false
          }
          break
        default:
          payload.isActive = !program.isActive
      }
      store.dispatch('program/updateProgram',
        { id: program.id, program: payload })
        .then(() => {
          this.refreshTable()
          switch (type) {
            case 1:
              this.showToast('active')
              break
            case 2:
              this.showToast('special offer')
              break
            case 3:
              this.showToast('reserved')
              break
            case 4:
              this.showToast('banner')
              break
            case 5:
              this.showToast('free')
              break
            default:
              this.showToast('active')
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update switch',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formatDate(time) {
      const date = time.split(' ')[0]
      return `${date.split('-')[0]}/${date.split('-')[1]}/${date.split('-')[2]}`
    },
    deleteProgram(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this program.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('program/deleteProgram', { id }).then(() => {
              this.refreshTable()
            })
          }
        })
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
